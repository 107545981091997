// Import JSX Modules
import Flux from 'flux';

const builderDispatch =
  (typeof window !== 'undefined' && window.builderDispatch) ||
  new Flux.Dispatcher();

const MAX_DELAYED_DISPATCHES = 5;

builderDispatch.delayedDispatches = {};

builderDispatch.register(payload => {
  if (!payload.type) {
    throw `invalid event: ${JSON.stringify(Object.keys(payload))}`;
  }

  if (!payload.delayed) {
    builderDispatch.delayedDispatches = {};
  }
});

builderDispatch.delayedDispatch = function(payload) {
  return new Promise(resolve => {
    const dispatch = () => {
      // Tell the register that this is delayed so it
      // can reset the count
      this.dispatch({
        ...payload,
        delayed: true,
        actuallyDelayed: builderDispatch.isDispatching(),
      });
      resolve();
    };

    if (typeof window !== 'undefined' && builderDispatch.isDispatching()) {
      this.delayedDispatches[payload.type] =
        this.delayedDispatches[payload.type] || 0;
      this.delayedDispatches[payload.type]++;

      Object.keys(this.delayedDispatches).forEach(key => {
        if (this.delayedDispatches[key] >= MAX_DELAYED_DISPATCHES) {
          throw `Too many delayed dispatches for ${key} in a row - MAX IS ${MAX_DELAYED_DISPATCHES}`;
        }
      });

      window.requestAnimationFrame(dispatch);
    } else {
      this.delayedDispatches = {};
      dispatch();
    }
  });
};

builderDispatch.register(payload => {
  if (window.debugDispatcher) {
    console.log(payload);
    window.builderDispatch = window.builderDispatch || builderDispatch;
    builderDispatch.recent = builderDispatch.recent || [];
    while (builderDispatch.recent.length > 50) {
      builderDispatch.recent.shift();
    }
    builderDispatch.recent.push({
      type: payload.type,
      payload,
      time: new Date(),
      stack: new Error().stack,
    });
  }
});

// Constants for flux event handling
const WebUI = {
  LOGIN: 'webui.login',
  PROMPT_LOGIN: 'webui.prompt-login',
  HIDDEN_LOGIN: 'webui.hide-login',
  ADD_TO_CART: 'webui.add-to-cart',
  ADDED_TO_CART: 'webui.added-to-cart',
  CART_MERGED: 'webui.cart-merged',
  RESIZE: 'webui.resize',
  EDIT_AREA_RESIZED: 'webui.edit-area-resized',
  TRAY_SCROLLED: 'webui.tray-scrolled',
  LOAD: 'webui.load',
  PAGES_CHANGED: 'webui.pages-changed',
  UPDATE_PHOTOS: 'webui.update-photos',
  UPDATE_FAVOURITES: 'webui.update-favourites',
  BEFORE_UPLOAD: 'webui.before_upload',
  UPLOAD_BEGUN: 'webui.upload-begun',
  UPLOAD_PROGRESS: 'webui.upload-progress',
  UPLOAD_ERROR: 'webui.upload-error',
  UPLOAD_PAUSED: 'webui.upload-paused',
  UPLOAD_RESUMED: 'webui.upload-resumed',
  UPLOAD_DONE: 'webui.upload-done',
  UPLOAD_BATCHED_DONE: 'webui.upload-batched-done',
  UPLOAD_BATCHED_IGNORE_FAILURES: 'webui.upload-batched-ignore-failures',
  UPLOAD_BATCHED_RETRY_FAILURES: 'webui.upload-batched-retry-failures',
  UPLOAD_SHOW_FAILURES: 'webui.upload-show-failures',
  UPLOAD_SHOW_BATCHED_FAILURES: 'webui.upload-show-batched-failures',
  UPLOAD_ABORTED: 'webui.upload-aborted',
  UPLOAD_IGNORE_FAILURES: 'webui.upload-ignore-failures',
  UPLOAD_PHOTO_REMOVED_FROM_TRAY: 'webui.upload-photo-removed-from-tray',
  UPLOAD_ALL_PHOTOS_REMOVED_FROM_TRAY:
    'webui.upload-all-photos-removed-from-tray',
  UPLOAD_CLOSED: 'webui.upload-closed',
  TEMPLATES_LOADED: 'webui.templates-loaded',
  TEMPLATE_CHANGED: 'webui.template-changed',
  GOTO_PAGE: 'webui.goto-page',
  PAGE_UPDATED: 'webui.page-updated',
  PAGE_NAV: 'webui.page-nav',
  SHOWING_SPREAD: 'webui.showing-spread',
  DO_SHOW_SPREAD: 'webui.do-show-spread',
  DO_NAV: 'webui.do-nav',
  ACTIVE_VIEW_CHANGED: 'webui.active-view-changed',
  ACTIVE_VIEW_UPDATED: 'webui.active-view-updated',
  ORIENTATION_CHANGED: 'webui.orientation-changed',
  ENTER_PREVIEW_MODE: 'webui.enter-preview',
  ENTER_EDIT_MODE: 'webui.enter-edit',
  CHANGE_VISIBLE_PAGE: 'webui.change-visible-page',
  HIDE_OPTION_NOT_AVAILABLE_FLYOUT: 'webui.hide-option-not-available-flyout',
  PHOTO_SERVICE_SELECTED: 'webui.photo-service-selected',
  PHOTO_SERVICE_CONNECTED: 'webui.photo-service-connected',
  PHOTO_EVENT_UPLOAD: 'webui.photo-event-upload',
  PHOTO_EVENT_ADVANCED_EDIT: 'webui.photo-event-advanced-edit',
  PHOTO_EVENT_RESIZE_ALL: 'webui.photo-event-resize_all',
  PHOTO_EVENT_CHANGE_QUANTITY: 'webui.photo-event-change-quantity',
  PHOTO_EVENT_PREVIEW: 'webui.photo-event-preview',
  PHOTO_EVENT_EDIT: 'webui.photo-event-edit',
  PHOTO_EVENT_ADD_TO_CART: 'webui.photo-event-add-to-cart',
  PHOTO_EVENT_REMOVE_PRINTS_MODAL: 'webui.photo-event-remove-prints-modal',
  PHOTO_EVENT_REMOVE_PRINTS_CONFIRM: 'webui.photo-event-remove-prints-confirm',
  PHOTO_EVENT_REMOVE_PRINTS_CANCEL: 'webui.photo-event-remove-prints-cancel',
  PHOTO_EVENT_CHANGE_CLICKED: 'webui.photo-change-clicked',
  SHOW_CUSTOM_PAGE: 'webui.show-custom-page',
  HIDE_CUSTOM_PAGE: 'webui.hide-custom-page',
  DONE_CUSTOM_PAGE: 'webui.done-custom-page',
  PAGES_TRAY_START_RELOAD: 'webui.pages-tray-start-reload',
  ADD_PAGE: 'webui.add-page',
  SHOW_PRINTS_PRICES: 'webui.show-prints-prices-table',
  SHOW_CONFIRMATION_DIALOG: 'webui.show-confirmation-dialog',
  SHOW_HOT_KEY_MODAL: 'webui.show-hot-key-modal',
  SHOWING_UPSELL_MODAL: 'prismui.showing-upsell-modal',
  PHOTO_TRAY_IMAGE_CLICKED: 'webui.photo-tray-image-clicked',
  ACCORDION_CLICKED: 'webui.accordion-clicked',
  STACKABLE_SLOT_ADDED: 'webui.stackable-slot-added',
  DROP_PREVIEW: 'webui.drop-preview',
  ADDED_USER_SAVED_EVENTS: 'webui.added-user-saved-events',
};

WebUI.Analytics = {
  SEND: 'webui.analytics-send',
  BEGIN_CHECKOUT_EVENT: 'webui.analytics-begin_checkout-event',
};

WebUI.PhotoEvents = {
  select: 'Select Photos',
  confirm: 'Add Photos',
  addtoprints: 'Add to Prints',
  pleaselogin: 'Please log in',
  getmorephotos: 'Get More Photos',
  event_add_photos: 'Add Photos',
  event_add_to_prints: 'Add to Prints',
  viewall: 'View All',
};

// Photo Builder Events for Omni Tracking
WebUI.PhotoEventStrings = {};
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_ADVANCED_EDIT] = 'Advanced Edit';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_RESIZE_ALL] = 'Resize All';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_CHANGE_QUANTITY] =
  'Change Quantity per Size';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_PREVIEW] = 'Preview and Buy';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_EDIT] = 'Edit';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_ADD_TO_CART] = 'Add to Cart';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_REMOVE_PRINTS_MODAL] =
  'Remove Prints Overlay';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_REMOVE_PRINTS_CONFIRM] =
  'Confirm Remove Prints';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_REMOVE_PRINTS_CANCEL] = 'Cancel';
WebUI.PhotoEventStrings[WebUI.PHOTO_EVENT_CHANGE_CLICKED] = 'Change';

const PrismUI = {
  FIT_TEMPLATE_AND_AUTOFILL: 'fit_template_and_autofill',
  CLOSE_ADD_PHOTOS_MODAL: 'webui.close-add-photos-modal',
  ADD_PHOTOS: 'prismui.open-add-photos',
  TRIGGER_ENVELOPE_ADDRESS_FORM: 'prismui.trigger-envelope-address',
  DAILY_TEXT_SLOTS: 'prismui.daily_text_slots',
  DO_ITEM_LOAD: 'prismui.do-item-load',
  DO_SHOW_SHELF: 'prismui.do-show-shelf',
  EVENT_ADD_PHOTOS: 'prismui.event-add-photos',
  EVENT_ADD_TO_CART: 'prismui.event-add-to-cart',
  EVENT_AUTO_FILL: 'prismui.event-auto-fill',
  EVENT_CHANGE: 'prismui.event-change',
  EVENT_COLORS: 'prismui.event-colors',
  EVENT_CUSTOMIZE_COVER: 'prismui.event-customize-cover',
  EVENT_CUSTOMIZE_DESIGNS: 'prismui.event-customize-designs',
  EVENT_CUSTOMIZE_ENVELOPE: 'prismui.event-customize-envelope',
  EVENT_CUSTOMIZE_LAYOUTS: 'prismui.event-customize-layouts',
  EVENT_EDIT_PROJECT_NAME: 'prismui.event-edit-project-name',
  EVENT_EDIT: 'prismui.event-edit',
  EVENT_HIDE_PHOTOS: 'prismui.event-hide-photos',
  EVENT_HIDE_USED: 'prismui.event-hide-used',
  EVENT_MORE_DESIGNS: 'prismui.event-more-designs',
  EVENT_PAGES: 'prismui.event-pages',
  EVENT_PREVIEW: 'prismui.event-preview',
  EVENT_QUANTITY_CHANGED: 'prismui.event-quantity-changed',
  EVENT_SAVE_PROJECT: 'prismui.event-save-project',
  EVENT_SHOW_PHOTOS: 'prismui.event-show-photos',
  EVENT_TEMPLATES: 'prismui.event-templates',
  FONTS_LOADED: 'prismui.fonts-loaded',
  HIDE_OVERLAY: 'prismui.hide-overlay',
  IMAGES_UPDATED: 'prismui.images-updated',
  ITEM_COPYING_FINISHED: 'prismui.item-copying-finished',
  ITEM_COPYING: 'prismui.item-copying',
  ITEM_LOADED: 'prismui.item-loaded',
  MODAL_MESSAGE: 'prismui.modal-message',
  OPEN_MY_EVENTS: 'prismui.open-my-events',
  OPEN_EDIT_MODAL: 'prismui.open-edit-modal',
  CLOSE_EDIT_MODAL: 'prismui.close-edit-modal',
  OPTION_SELECTED: 'prismui.option-selected',
  PAGE_CHANGED_OUTSIDE_EDITOR: 'prismui.page-changed-outside-editor',
  PREVIEW_2D_LOADING: 'prismui.preview-2d-loading',
  PREVIEW_2D_LOADED: 'prismui.preview-2d-loaded',
  SAVE_DONE: 'prismui.save-done',
  SAVE_START: 'prismui.save-start',
  SHOW_HELP_OVERLAY: 'prismui.show-help-overlay',
  SLOT_CHANGED: 'prismui.slot-changed',
  SLOT_CLICK: 'prismui.slot-click',
  SLOT_ACTIVATE: 'prismui.slot-activate',
  SLOT_DATA_CHANGED: 'prismui.slot-data-changed',
  SLOT_MOVED: 'prismui.slot-moved',
  OPEN_TEXT_SLOT: 'prismui.open-text-slot',
  CLOSE_TEXT_SLOT: 'prismui.close-text-slot',
  THEME_CHANGED: 'webui.theme-changed',
  THEMES_LOADED: 'webui.themes-loaded',
  UNDO_CHANGED: 'prismui.undo-changed',
  ZOOM_CHANGED: 'prismui.zoom-changed',
  DESIGNS_FILTER: 'webui.designs-filter',
  FOCUS_EDIT_TEXT: 'prismui.focus.edit.text',
  SLOT_FOCUSED: 'prismui.slot.focused',
  ADD_PAGE_BUTTONS_BLOCKED_ON_RETURN_KEY:
    'prismui.add-page-buttons-blocked-on-return-key',
  DELETE_PHOTO_FROM_SLOT: 'prismui.delete-photo-from-slot',
  EASY_DROP_SAVE: 'easy-drop-save',
  CLOUD_SERVICE_HEADER: 'cloud-service-header',
  RICH_TEXT_MOUNTED: 'rich-text-mounted',
  PRODUCT_CHANGED: 'product-changed',
  TEXT_SLOT_DOUBLE_CLICK: 'text-slot-double-click',
  TEXT_SLOT_CONTROLS_POSITION: 'text-slot-controls-position',
  TEXT_SLOT_CLICK_FOR_FIXED: 'text-slot-click-for-fixed',
  PRISM_VIEW_INIT: 'prismui.prism-view-init',
  PRISM_VIEW_DEINIT: 'prismui.prism-view-deinit',
};

// Prism Builder Events for Omni Tracking
PrismUI.EventStrings = {};
PrismUI.EventStrings[PrismUI.EVENT_CUSTOMIZE_COVER] = 'Customize Cover';
PrismUI.EventStrings[PrismUI.EVENT_CUSTOMIZE_DESIGNS] = 'Customize Designs';
PrismUI.EventStrings[PrismUI.EVENT_CUSTOMIZE_LAYOUTS] = 'Customize Layouts';
PrismUI.EventStrings[PrismUI.EVENT_MORE_DESIGNS] = 'More Designs';
PrismUI.EventStrings[PrismUI.EVENT_TEMPLATES] = 'Templates';
PrismUI.EventStrings[PrismUI.EVENT_COLORS] = 'Colors';
PrismUI.EventStrings[PrismUI.EVENT_CUSTOMIZE_ENVELOPE] = 'Customize Envelope';
PrismUI.EventStrings[PrismUI.EVENT_PREVIEW] = 'Preview';
PrismUI.EventStrings[PrismUI.EVENT_EDIT] = 'Edit';
PrismUI.EventStrings[PrismUI.EVENT_ADD_TO_CART] = 'Add To Cart';
PrismUI.EventStrings[PrismUI.EVENT_SAVE_PROJECT] = 'Save Project';
PrismUI.EventStrings[PrismUI.EVENT_EDIT_PROJECT_NAME] = 'Edit Project Name';
PrismUI.EventStrings[PrismUI.EVENT_CHANGE] = 'Change';
PrismUI.EventStrings[PrismUI.EVENT_AUTO_FILL] = 'Auto Fill';
PrismUI.EventStrings[PrismUI.EVENT_ADD_PHOTOS] = 'Add Photos';
PrismUI.EventStrings[PrismUI.EVENT_HIDE_USED] = 'Hide Used';
PrismUI.EventStrings[PrismUI.EVENT_HIDE_PHOTOS] = 'Hide Photos';
PrismUI.EventStrings[PrismUI.EVENT_SHOW_PHOTOS] = 'Show Photos';
PrismUI.EventStrings[PrismUI.EVENT_PAGES] = 'Pages';

const ShelfUI = {
  FILTER_SELECTED: 'shelf.filter-selected',
  HORIZONTAL_FILTER_SELECTED: 'shelf.horizontal-filter-selected',
  HORIZONTAL_DELIVERY_OPTION_SELECTED:
    'shelf.horizontal-delivery-option-selected',
};

// Shelf Events for Omni Tracking
ShelfUI.EventStrings = {};
ShelfUI.EventStrings[ShelfUI.FILTER_SELECTED] = 'Filter Selected';

function objectName(obj) {
  if (obj && obj._reactInternalInstance && obj._reactInternalInstance.getName) {
    return obj._reactInternalInstance.getName();
  }
  return obj.toString();
}

const RefreshOnEvents = {
  componentDidMount() {
    this._refresh_handler = builderDispatch.register(payload => {
      if (this.refreshOn instanceof Array) {
        if (this.refreshOn.indexOf(payload.type) !== -1) {
          if (this.beforeEventRefresh) {
            this.beforeEventRefresh(payload);
          }

          this.forceUpdate(() => {
            if (this.onEvent) {
              this.onEvent(payload);
            }
          });
        }
      } else {
        console.log('No events defined for %o', objectName(this));
      }
    });
  },
  componentWillUnmount() {
    builderDispatch.unregister(this._refresh_handler);
  },
};

const TrackPreviewMode = {
  getInitialState() {
    return { mode: 'edit-mode' };
  },
  componentDidMount() {
    this._preview_handler = builderDispatch.register(payload => {
      if (payload.type === WebUI.ENTER_PREVIEW_MODE) {
        this.setState({ mode: 'preview-mode' });
      } else if (payload.type === WebUI.ENTER_EDIT_MODE) {
        this.setState({ mode: 'edit-mode' });
      }
    });
  },
  componentWillUnmount() {
    builderDispatch.unregister(this._preview_handler);
  },
};

const UI_STATE = {
  bottomTray: true,
  collageTemplateFitAndAutofill: false,
};

export {
  UI_STATE,
  TrackPreviewMode,
  RefreshOnEvents,
  PrismUI,
  WebUI,
  ShelfUI,
  builderDispatch,
};
